<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "小程序直播间" }}</template>
            <template v-slot:toolsbarLeft></template>
            <template v-slot:toolsbarRight>
                <Button type="primary" style="margin-right: 10px" @click="updateSelect(2)">批量隐藏</Button>
                <Button type="info" style="margin-right: 10px" @click="updateSelect(1)">批量显示</Button>
                <Button type="primary" :loading="btnLoading" @click="updateLive()">更新直播间</Button>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            // table表格数据
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "主题",
                    key: "roomName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "房间号",
                    key: "roomId",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "主播",
                    key: "anchorName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "直播时间",
                    key: "startTime",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.startTime && params.row.startTime != "" ? this.$core.formatDate(new Date(params.row.startTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "直播状态",
                    key: "recommend",
                    width: 300,
                    align: "center",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h(
                                    "RadioGroup",
                                    {
                                        attrs: {
                                            value: params.row.liveStatus,
                                        },
                                        on: {
                                            "on-change": status => {
                                                let data = {
                                                    infoId: params.row.infoId,
                                                    liveStatus: Number(status),
                                                }
                                                this.updateLiveStatus(data)
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "1",
                                                },
                                            },
                                            "回放"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "2",
                                                },
                                            },
                                            "未开始"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "3",
                                                },
                                            },
                                            "直播中"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "状态",
                    key: "recommend",
                    width: 165,
                    align: "center",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h(
                                    "RadioGroup",
                                    {
                                        attrs: {
                                            value: params.row.status,
                                        },
                                        on: {
                                            "on-change": status => {
                                                let data = {
                                                    infoId: params.row.infoId,
                                                    status: Number(status),
                                                }
                                                this.update(data)
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "1",
                                                },
                                            },
                                            "显示"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "2",
                                                },
                                            },
                                            "隐藏"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,

            selectList: [],
            btnLoading: false,
        }
    },
    async created() {
        this.getList()
    },
    methods: {
        // 更新
        updateLiveStatus(params) {
            this.$post(
                "/gateway/api/sycomponent/pc/broadcast/updateLiveStatus",
                {
                    ...params,
                }
            ).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "设置成功",
                        background: true,
                    })
                    this.getList()
                } else {
                    this.$Message.error({
                        content: "设置失败",
                        background: true,
                    })
                    this.getList()
                }
            })
        },
        // 更新直播间
        updateLive() {
            this.btnLoading = true
            this.$post(
                "/gateway/api/sycomponent/pc/broadcast/buildBroadcastInfo",
                {
                    oemCode: parent.vue.oemInfo.oemCode,
                }
                // { "Content-Type": "application/json" }
            ).then(res => {
                this.btnLoading = false
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "更新直播间成功",
                        background: true,
                    })
                    this.getList()
                } else {
                    this.$Message.error({
                        content: "更新直播间失败",
                        background: true,
                    })
                    this.getList()
                }
            })
        },
        updateSelect(val) {
            if (this.selectList && this.selectList.length) {
                let data = []
                this.selectList.map(item => {
                    data.push(item.infoId)
                })
                this.update({
                    infoId: data.join(","),
                    status: val,
                })
            } else {
                this.$Message.warning({
                    content: "请选择数据",
                    background: true,
                })
                return
            }
        },
        // 选择表格事件
        tableSelect(val) {
            this.selectList = val
        },
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList() {
            this.$get("/gateway/api/sycomponent/pc/broadcast/listWxBroadcastByPage", {
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.tableData = []
                this.selectList = []
                this.$nextTick(() => {
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                            startTime: item.startTime * 1000,
                            status:!item.status || item.status == '' ? '2' : item.status
                        }
                    })
                })

                this.total = res.maxCount
            })
        },
        update(params) {
            this.$post(
                "/gateway/api/sycomponent/pc/broadcast/updateBatchStatus",
                {
                    ...params,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "设置成功",
                        background: true,
                    })
                    this.getList()
                } else {
                    this.$Message.error({
                        content: "设置失败",
                        background: true,
                    })
                    this.getList()
                }
            })
        },
    },
}
</script>
<style lang="less">
</style>